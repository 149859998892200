.navbar {
  font-size: 14px;
  @media (min-width: $screen-lg-min) { font-size: 18px; }
  .navbar-brand {
	padding: 0 0;
  }
  .dropdown-menu {
	font-size: 14px;
	a, a:hover, a:visited, a:active, a:focus {
	  color: #fff;
	}
  }
}


