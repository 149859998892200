
.datePickerWrap {
	position: relative;
}

img.ui-datepicker-trigger{
  position: absolute;
  z-index: 10;
  top: 0;
  right: 1px;
  display: block;
  padding: 0;
  cursor: pointer;
  line-height: 10px;
}
