.news-list {

	padding-bottom: 15px;

	.head {
		text-align: center;
	}

	.news {
		padding-top: 8px;
		padding-bottom: 8px;
		border-top: 1px solid $gray-lighter;

		.news-image {
			padding: 4px;
			line-height: 1.428571429;
			//border: 1px solid #ddd;
			border-radius: 4px;
			transition: all 0.2s ease-in-out;
			display: inline-block;
			width: 180px;
			height: 180px;
			background-size: auto;
			background-repeat: no-repeat;
			background-position: center center;

			&.landscape-ultra {
				width: 180px;
				height: 100px;
			}

			&.landscape {
				width: 180px;
				height: 120px;
			}

			&.square {
				width: 180px;
				height: 180px;
			}
			&.portrait {
				width: 180px;
				height: 220px;
			}
		}

		.title {
			font-size: $font-size-h4;
			line-height: 1.1;
			font-weight: 500;
		}

		.news-date {
			color: $gray-light;
		}
	}

	.links {
		text-align: center;
	}
}