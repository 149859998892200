.route.page-section {

	label {
		margin: 0;
	}

	.title {
		font-weight: 500;
		margin-top: 7px;
		margin-bottom: 7px;
		font-size: $font-size-h4;
		&.route-name{
			color: $brand-green;
		}
	}
	.note {
		color: $gray-light;
		&.graph {
			font-size: $font-size-base;
		}
	}

	.datePickerWrap {
		width: 200px;
	}


	dd {
		margin-top: 7px;
		margin-bottom: 7px;
	}

	dd, dt {
		font-weight: 500;
		font-size: $font-size-h4;
	}

	dd {
		color: $brand-green;
	}

	.departures {
		.state-1, .state-4 {
			color: #2a9106;
			font-weight: bold;
		}
		.state-2 {
			color: #b93405;
			font-weight: bold;
		}
		.state-3, .state-5 {
			color: #5c0691;
			font-weight: bold;
		}
	}


}