.et-about-bar {
	background-color: #edeeef;

	.about-col {
		background-color: $block-bg;
		margin: 25px 0;
		padding: 15px;
		min-height: 220px;

		h3 {
			margin-top: 0;
		}
	}

}