.container.faq {

	.post-list {
		margin-top: 15px;
	}

	.post {
		margin-bottom: 15px;
		border-bottom: solid #c6d3d6 1px;

		.head {
			padding: 5px 15px;
		}

		.post-body {
			padding: 15px;
			min-height: 70px;
		}

		.note {
			color: $gray-light;
		}

	}

}