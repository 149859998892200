.route-list-section {

	&.main-head {
		margin-bottom: 0;
	}
}

.route-list {

	margin-top: 15px;

	.route-block {
		@include make-row;
		position: relative;
		padding-top: 15px;
		padding-bottom: 15px;
		border-top: 1px solid $gray-lighter;

		.title {
			font-weight: 500;
			margin-top: 7px;
			margin-bottom: 7px;
			&.route-name {
				a {
					font-size: $font-size-h4;
					color: $brand-green;
				}

			}
		}

		.note {
			color: $gray-light;
		}

		.sep {
			color:lighten($gray-base, 60%);
		}

		.col-route-num {
			position: relative;
			min-height: 1px;
			padding-left:  30px;
			padding-right: 15px;
			float: left;
			width: auto;
		}

		.col-describe {
			position: relative;
			min-height: 1px;
			padding-left:  15px;
			padding-right: 15px;
			float: left;
			width: auto;
			max-width: 400px;
		}

		.col-price {
			position: relative;
			float: right;
			width: 200px;
			overflow: hidden;
			min-height: 1px;
			text-align: center;
			vertical-align: middle;

			.title {
				font-size: $font-size-h4;
				margin: 15px;
			}


		}


		.route-label {
			font-weight: 500;
			color: $brand-green;
			display: inline-block;
			width: 75px;
			border: 1px solid $brand-green;
			text-align: center;
			vertical-align: middle;
			white-space: nowrap;
			padding: 7px 3px;
			overflow: hidden;
		}

	}

}