.search-bar {
	padding-top: 25px;
	padding-bottom: 25px;
	text-align: center;
	background-color: $block-bg;

	$margin-horizontal: 10px;
	$margin-vertical: 5px;


	.from-input {
		min-width: 250px;
		margin: $margin-vertical $margin-horizontal;
	}
	.to-input {
		min-width: 250px;
		margin: $margin-vertical $margin-horizontal
	}

	.datePickerWrap {
		margin: $margin-vertical $margin-horizontal
	}

	.date-input {
		min-width: 150px;
	}

	.btn {
		min-width: 150px;
		margin: $margin-vertical $margin-horizontal;
	}

}

