@import "samal/variables";
@import "bootstrap";
@import "samal/form";
@import "samal/navbar";
@import "samal/footer";
@import "samal/header";
@import "samal/search-bar";
@import "samal/et-scheme";
@import "samal/et-about-bar";
@import "samal/et-why";
@import "samal/route-map";
@import "samal/news-list";
@import "samal/route-list";
@import "samal/route";
@import "samal/bus-scheme";
@import "samal/eticket";
@import "samal/faq";
@import "samal/order-form";

html {
	min-height: 100%;
}


body {
	.wrapper {
		padding: 0 0 0 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	}
}

.row {
  .cell {
	display: inline-block;
  }
}

.main-head {
	background-color: $brand-bronze;
	margin-bottom: 25px;
	float: left;
	width: 100%;

	h1 {
		font-size: $font-size-h4;
		color: #fff;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.page-section {
	min-height: 600px;
	margin-bottom: 20px;
}


.sidebar-col {
	border-left: 1px solid $gray-lighter;
}
.docs-sidebar {

	max-width: 200px;

	.tip {
		background-color: #fcf8e3;
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid #faebcc;
		border-radius: 0;
	}

}

.action-link {

	font-style: italic;
	cursor: pointer;
	border-bottom: 1px dotted;
	color: $link-color;
	font-weight: 100;
	font-size: $font-size-base;
	white-space: nowrap;
	&:hover {
		color: darken($link-color, 15%)
	}
}

table.my-table-hover {
	> tbody > tr:hover {
		background-image: url("/images/row.png");
		background-position: bottom left;
		background-repeat: repeat-x;
	}

	> tbody > tr.active {
		background-image: url("/images/row-active.png");
		background-position: bottom left;
		background-repeat: repeat-x;
	}
}


table thead {
	background-color: #f8f3e1;
}



.message-block {
	margin-bottom: 15px;
	background-color: #fcf8e3;
	padding: 10px;
	border: 1px solid #faebcc;
	border-radius: 0;
	text-align: center;
	&.info {
		color: #000;
	}

	&.warning {
		font-weight: bold;
		color: #9a1105;
	}

}

// исправление бага в affix
.affix-bottom {
	position: relative
}


.flex-container {
	display: flex;

	.flex-block-center {
		margin: auto;  /* Магия! Блок отцентрирован по вертикали и горизонтали! */
	}
}

