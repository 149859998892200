.et-scheme {
	padding-top: 10px;
	padding-bottom: 0;
	text-align: center;
	background-color: $block-bg;

	h2 {
		margin-top: 5px;
		margin-bottom: 20px;
	}

	.steps {
		background-color: #fff;

		a.step-block {

			display: inline-block;

			h4 {
				margin-bottom: 0;
			}

			.step {

				width: 121px;
				height: 121px;
				display: inline-block;

				&.step-1 {
					background: url('/images/samal/step-sprite.png') no-repeat;
					background-position: 0 0;
				}

				&.step-2 {
					background: url('/images/samal/step-sprite.png') no-repeat;
					background-position: 0 -120px;
				}

				&.step-3 {
					background: url('/images/samal/step-sprite.png') no-repeat;
					background-position: 0 -240px;
				}
			}

		}

		a.step-block:hover {

			.step {
				&.step-1 {
					background-position: -120px 0;
				}

				&.step-2 {
					background-position: -120px -120px;
				}

				&.step-3 {
					background-position: -120px -240px;
				}
			}

		}

	}

}

