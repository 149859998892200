.eticket.page-section {

	.scheme-block {
		padding-top: 15px;
		padding-bottom: 15px;
		width: 710px;
		.col-step {
			display: inline-block;
			min-height: 1px;
			text-align: center;
			vertical-align: middle;
			padding: 10px;
			// 710px
			&.step-1 {
				width: 200px;
			}
			&.step-2 {
				width: 250px;
			}
			&.step-3 {
				width: 250px;
			}
		}
	}

	.sub-text {
		margin-left: 30px;
	}

	h4 {
		margin-top: 20px;
	}

	.go-to-link {
		font-size: $font-size-large;

	}

}

.eticket-how-to-pay.page-section {

	.pay-logo {
		margin: 10px;
	}

	.section-pay {
		margin-bottom: 20px;
		border-top: 1px solid $gray-lighter;
	}



}