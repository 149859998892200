header {
  background-color: $brand-green;
  color: #fff;
  a, a:hover, a:visited, a:active, a:focus {
	color: #fff;
  }
  .cell {
	height: 25px;
	margin: 0 15px;
	padding: 5px 0;
	font-size: 12px;
  }
}
