.eticket-order-form.page-section {

	.main-info {
		font-weight: bold;
		//font-size: $font-size-h4;
		//margin: 0 0 4px 0;
	}

	.sup {
		font-size: 10px;
		top: -5px;
	}

	.order-section {
		margin-bottom: 15px;
		border-bottom: 1px solid $gray-lighter;
	}

	.dl-route-custom {
		$dl-route-custom-offset: 120px;

		dd {
			@include clearfix; // Clear the floated `dt` if an empty `dd` is present
		}

		@media (min-width: $grid-float-breakpoint) {
			dt {
				float: left;
				width: ($dl-route-custom-offset - 15);
				clear: left;
				text-align: left;
				@include text-overflow;
			}
			dd {
				margin-left: $dl-route-custom-offset;
			}
		}
	}

	.field-row {
		padding-bottom: 15px;
	}

	.place-num {
		padding-right: 6px;
	}


}