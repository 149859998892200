.et-why {

	.head {
		text-align: center;
	}

	.checkers {

		padding: 15px;
		height: 150px;
		overflow: hidden;
		vertical-align: middle;

		&.white {
			background-color: #fff;
		}
		&.gray {
			background-color: #edeeef;
		}

		.icon-col {

			text-align: center;

			.because-icon {
				width: 72px;
				height: 72px;
				display: inline-block;
				&.saving {
					background: url('/images/samal/why-sprite.png') no-repeat;
					background-position: 0 0;
				}
				&.no-queue {
					background: url('/images/samal/why-sprite.png') no-repeat;
					background-position: 0 -72px;
				}
				&.no-lose {
					background: url('/images/samal/why-sprite.png') no-repeat;
					background-position: 0 -144px;
				}
				&.secure {
					background: url('/images/samal/why-sprite.png') no-repeat;
					background-position: 0 -216px;
				}
				&.easy {
					background: url('/images/samal/why-sprite.png') no-repeat;
					background-position: 0 -288px;
				}
				&.question {
					background: url('/images/samal/why-sprite.png') no-repeat;
					background-position: 0 -360px;
				}
			}
		}

		.desc-col {
			display: none;
		}
	}

	.checkers:hover {

		background-color: $brand-green;
		color: #fff;

		.icon-col {
			display: none;
		}

		.desc-col {
			display: inline-block;
		}
	}



}