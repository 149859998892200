.footer {
	background-color: $brand-green;
	color: #fff;
	a, a:hover, a:visited, a:active, a:focus {
		color: #fff;
	}

	h4 {
		color: #fff;
	}
	.row {
		.cell {
			display: table-cell;
			padding-left: 10px;
			vertical-align: middle;
			text-align: left;
		}
	}
	.it-block {
		margin: 15px 0;
		display: table;
	}
	.footer-col {
		padding: 10px 10px;
	}

	.menu-list {
		padding-left: 0;
		list-style: none;
		li {
			vertical-align: middle;
			height: 35px;
			padding-top: 7px;
			padding-bottom: 7px;
		}
		li:not(:first-child) {
			border-top: 1px solid #fff;
		}
	}
	.copyright-block {
		background-color: #666666;
		.copyright-info {
			font-size: 12px;
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}

}
