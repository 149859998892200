.route-map {
	.head {
		text-align: center;
	}

	.map-col {

		margin-bottom: 15px;

		.map-container {
			width: auto;
			height: 500px;
		}
	}

}