.bus-scheme-container {

	$cell-size: 44px;
	$cell-padding: 3px;
	$place-size: $cell-size - ($cell-padding * 2);

	$cell-small-size: 30px;
	$cell-small-padding: 2px;
	$place-small-size: $cell-small-size - ($cell-small-padding * 2);


	$free-bg-color:  #c2fab5;
	$busy-bg-color : #fa9c86;
	$bron-bg-color : #db8afa;

	width: auto;
	text-align: center;

	&.flash-scheme {

		.flash-box {
			width: 769px;
			height: 230px;
			background-image: url("/images/flash.png");
			display: inline-flex;

			.flash-message {
				background-color: #fcf8e3;
				padding: 20px;
				border: 1px solid #edcb7a;
				border-radius: 0;
				text-align: center;
				margin: auto;
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
			}

		}

	}


	.bus-scheme {

		margin:0 auto;

		td {
			width: $cell-size;
			height: $cell-size;
			padding: $cell-padding;
			border: 1px dotted transparent;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			.place {
				cursor: pointer;
				display: inline-block;
				border: 1px solid $gray-light;
				background-color: transparent;
				width: $place-size;
				height: $place-size;
				text-align: center;
				vertical-align: middle;
				padding-top: floor($place-size / 2 - $line-height-computed / 2);
				&.driver {
					background-color: #cbc5c6;
					width: 40%;
					height: 50%;
					margin: auto;
					padding: 0;
					cursor: default;
				}
				&.state-0 {
					background-color: $free-bg-color;
					&:hover {
						background-color: darken($free-bg-color, 10%);
					}
					&.selected {
						background-color: darken($free-bg-color, 50%);
						color: #fff;
						border-color: #000;
					}
				}
				&.state-1 {
					background-color: $busy-bg-color;
				}
				&.state-4, &.state-5 {
					background-color: $bron-bg-color;
				}

			}
			&.driver-cabin {
				min-width: $cell-size * 2;
				min-height: $cell-size * 2;
			}

			&.brd-top {
				border-top: 1px solid $gray-light;
			}

			&.brd-left {
				border-left: 1px solid $gray-light;
			}

			&.brd-bottom {
				border-bottom: 1px solid $gray-light;
			}
			&.brd-right {
				border-right: 1px solid $gray-light;
			}

		}

		&.scheme-small {

			td {
				width: $cell-small-size;
				height: $cell-small-size;
				padding: $cell-small-padding;
				.place {
					font-size: $font-size-small;
					width: $place-small-size;
					height: $place-small-size;
					padding-top: floor($place-small-size / 2 - 8);

				}
				&.driver-cabin {
					min-width: $cell-small-size * 2;
					min-height: $cell-small-size * 2;
				}

			}

		}

	}

	.legend {
		margin: 15px;
		.mark {
			display: inline-block;
			border: 1px solid $gray-light;
			width: $font-size-small;
			height: $font-size-small;
			&.state-0 {
				background-color: $free-bg-color;
			}
			&.state-1 {
				background-color: $busy-bg-color;
			}
			&.state-4 {
				background-color: $bron-bg-color;
			}

		}
	}

}

